<template>
<PortalLgpd :showMenu="showMenu" :origem="origem"/>
</template>

<script>

import PortalLgpd from "@/components/PortalLgpd.vue";
export default {
  name: "ViewLgpd",
  components: {
    PortalLgpd,
  },
  props: {
    showMenu: {
      type: Boolean,
      default: false
    },
    origem: {
      type: String,
      default: 'Não Informado'
    }
  }
}
</script>
